import SensorMonitorIcon from "../images/Sensor-Monitor-white-web.png"
import React from "react"
import logo from "../images/joensuu_logo.svg"
import useWindowDimensions from "../lib/useWindowDimensions"

export default class Joensuu {
  static init() {
    import('../styles/themes/joensuu.css').then((_condition) => {});
  }

  static headerHeight() {
    const {windowWidth, windowHeight} = useWindowDimensions()
    return Math.min(windowWidth, windowHeight) < 512 ? 70 : 110
  }

  static Header() {
    return (
      <header className="app-header" style={{height: Joensuu.headerHeight()}}>
        <a className="logo" href="https://www.joensuu.fi" title="Mene joensuu.fi">
          <img alt="Joensuu" className="app-logo" src={logo} width={2 * Joensuu.headerHeight()} height={0.4 * Joensuu.headerHeight()}/>
        </a>
        <span className="app-header-title">
          CityMonitor
        </span>
      </header>
    )
  }

  static Footer() {
    return (
      <div className="app-footer">
        Powered by <a href="https://www.sensormonitor.fi"><img width={26} src={SensorMonitorIcon}/></a>
      </div>
    )
  }
}